@import 'assets/styles/mixins.scss';

.container {
  max-width: rem(1280);
  padding: 0 rem(30);
  margin: 0 auto;

  a {
    &:hover {
      color: $gray-5;
    }
  }
}

.footerFullWidth {
  max-width: none;
}

.logo {
  line-height: 1;

  img {
    float: left;
    margin-top: rem(5);
  }
}

.logoName {
  font-weight: 900;
  color: $white;
  font-size: 21px;
  margin-left: rem(40);
}

.logoDescr {
  color: $gray-4;
  margin-left: rem(40);
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
