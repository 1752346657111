@import 'assets/styles/mixins.scss';

.closeBtn {
  padding: 0;
  border: none;
}

.contentWrapper {
  padding-right: 15px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: rem(15);
  overflow: hidden;
  flex-shrink: 0;

  &.answer {
    flex-direction: row-reverse;

    .messageAvatar {
      margin-left: 0;
      margin-right: rem(15);
    }

    .messageContent {
      &::before {
        left: auto;
        right: 100%;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 4px solid $gray-1;
        border-left: none;
      }
    }
  }
}

.messageAvatar {
  flex-shrink: 0;
  margin-left: rem(15);
}

.messageContent {
  background-color: $gray-1;
  position: relative;
  border-radius: 5px;
  padding-left: rem(15);
  padding-right: rem(15);
  padding-top: rem(6);
  padding-bottom: rem(6);

  &::before {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 16px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 4px solid $gray-1;
    border-right: none;
  }
}
