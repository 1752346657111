@import 'assets/styles/mixins.scss';

.topbar {
  background: transparent;
  //min-height: rem(64);
  border-bottom: 1px solid $border;
  padding: rem(5) rem(5);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

// added additional CSS Props
.divider {
  width: 1px;
  background-color: $border;
  align-self: stretch;
  flex-grow: 0;
  flex-shrink: 0;
}

// .breadcrumbs {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   padding-left: 0;
//   margin-bottom: 0;
//   list-style: none;
//   font-size: rem(18);
// }

.breadcrumbs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: rem(18);
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  margin-right: rem(30);

  &::after {
    content: '-';
    position: absolute;
    right: rem(-18);
    bottom: 0;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
}

.breadcrumbLink {
  display: block;

  &__current {
    color: $black;
    font-weight: 700;
    pointer-events: none;
  }
}
