@import 'assets/styles/mixins.scss';

.topbarDark {
  min-height: rem(64);
  border-bottom: 1px solid $border;
  padding: rem(15) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  background: $black;

  &__logo {
    display: block;
    padding-top: rem(15);
    padding-bottom: rem(15);
    margin-right: rem(30);
    line-height: 1;
    height: rem(64);

    img {
      float: left;
      margin-top: rem(5);
    }
    &__name {
      font-weight: 900;
      color: $white;
      font-size: 21px;
      margin-left: rem(40);
    }
    &__descr {
      color: $gray-4;
      margin-left: rem(40);
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}
