@import 'assets/styles/mixins.scss';

.dropdown {
  padding-top: rem(10);
  padding-bottom: rem(10);
  padding-right: 35px;
  padding-left: 20px;
  background-color: $dark-2;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  @include transition-slow;
  color: $gray-5;

  &::after {
    color: $gray-5;
    position: absolute;
    top: 50%;
    right: 15px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    @include transition-slow;
  }

  &:hover {
    color: $white;

    &::after,
    .icon {
      color: $white;
    }
  }
}

.title {
  display: block;
  padding: rem(8) rem(20);
}

.icon {
  margin-right: rem(6);
  color: $gray-5;
  position: relative;
  bottom: -0.14rem;
  @include transition-slow;
}
